<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <LoaderBig v-if="project === null" />

    <b-card v-if="!widgets.length">
      <b-card-body>
        <div v-if="project === false" class="text-center mt-5">
          <h4>{{$t('General.NoProject.Title')}}</h4>
          <small>{{$t('General.NoProject.Desc')}}</small>
          <div class="mt-4">
            <router-link class="btn btn-success btn-lg" to="/projects">
              {{$t('General.NoProject.ToProjects')}}
              <i class="ri-arrow-right-line align-middle ml-2"></i>
            </router-link>
          </div>
        </div>

        <div v-if="project === true" class="text-center mt-5">
          <h4>{{$t('Widget.NoWidgetTitle')}}</h4>
          <small>{{$t('Widget.NoWidgetDesc')}}</small>
          <div class="mt-4">
            <b-button size="lg" variant="success" @click="createWidget()">
              {{$t('Widget.GoToConstructor')}}
              <i class="ri-arrow-right-line align-middle ml-2"></i>
            </b-button>
          </div>
        </div>

        <div class="row mt-5 justify-content-between">
          <div class="col-6">
            <blockquote class="blockquote font-size-16 mb-0" v-html="$t('Widget.WidgetInfo')">
            </blockquote>

          </div>
          <div class="col-5 pt-2">
<!--            <b-card header-class="bg-transparent border-info" class="border border-info">-->
<!--              <h5 class="mt-0 mb-4 text-info">-->
<!--                <i class="ri-settings-3-line mr-3"></i> -->
<!--              </h5>-->
<!--              <p class="card-text">-->
<!--              </p>-->
<!--            </b-card>-->
          </div>
        </div>

      </b-card-body>

    </b-card>

    <b-card
        v-for="(widget, i) in widgets"
        :key = i
    >
      <b-row align-h="between">
        <b-col cols="col-auto" class="pt-2">
          <h6>
            #{{widget.id}}
            <span v-if="!widget.name">{{$t('Widget.WidgetNoName')}}</span>
            <span v-if="widget.name">{{widget.name}}</span>
          </h6>
        </b-col>
        <b-col cols="col-auto">
<!--          <b-input placeholder="Без названия" class="d-inline" :value="widget.name"/>-->
          <router-link class="btn btn-success" :to="'/constructor/' + widget.id">
            {{$t('Widget.Constructor')}}
          </router-link>
        </b-col>
      </b-row>




<!--      {{widget}}-->
    </b-card>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import router from "@/router";
import {widgetService} from "@/helpers/fakebackend/widgets.service";
import LoaderBig from "@/components/LoaderBig"

export default {
  name: "Widgets",
  components: { Layout, PageHeader, LoaderBig },
  page: {
    title: "Widget",
    meta: [{ name: "Widget" }],
  },
  data: function () {
    return {
      title: this.$t('Widget.Constructor'),
      items: [
        {
          text: this.$t('General.Breadcrumbs.Main'),
          href: "/",
        },
        {
          text: this.$t('General.Breadcrumbs.Widget'),
          active: true,
        },
      ],
      project: null,
      widgets: []
    }
  },
  computed: {
    currentProjects: function() {
      return this.$store.getters['projects/getCurrentProjects']
    }
  },
  methods: {
    ...widgetService,
    createWidget() {
      let data = {
        project_id: this.currentProjects.id,
        name: ''
      }

      this.createTag(data).then(tag => {
        router.push('/constructor/' + tag.id + '?r=widgets')
      })
    }
  },
  mounted() {
    this.$store.dispatch('projects/getCurrentProjects').then((project_id) => {
      if (!project_id) {
        this.project = false
        return
      }
      this.$store.commit('tag/setProject',
          {
            project_id: this.$store.getters['projects/getCurrentProjects'].id
          }
      )

      this.getWidgets(this.currentProjects.id).then(result => {
        this.widgets = result
        this.project = true
      })
    })


  }
}
</script>

<style scoped>

</style>
