<template>
  <b-card class="p-5">
    <div class="text-center">
      <b-spinner class="mr-4" variant="success" role="status"></b-spinner>
      <h4 class="d-inline">{{$t('Components.LoaderBig')}}</h4>
    </div>
  </b-card>
</template>

<script>
export default {
  name: "LoaderBig"
}
</script>

<style scoped>

</style>
