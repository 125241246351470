import api from "@/helpers/fakebackend/api";

export const widgetService = {
    getWidgets,
    getWidget,
    createWidget,
    updateWidget,
    getTemplateWidgets,
    getTemplateWidgetsTags,
    getTemplateWidget,
};

async function getWidgets(obj) {
    const request = {
        method: 'get',
        path: 'tags/tag-source/index',
        params: obj
    }
    return (new api()).sendRequest(request).then(response => {
        return new Promise((resolve, reject) => {
            if (response.data.status === 'success') {
                resolve(response.data.data)
            }
            if (response.data.status === 'error') {
                reject(response.data.data)
            }
        });
    })
}

function getWidget(data) {
    const request = {
        method: 'get',
        path: 'tags/tag-source/view',
        params: data
    }

    return (new api()).sendRequest(request).then(response => {
        response.data.data.data = JSON.parse(response.data.data.data)
        return new Promise((resolve, reject) => {
            if (response.data.status === 'success') {
                resolve(response.data.data)
            }
            if (response.data.status === 'error') {
                reject(response.data.data)
            }
        });
    })
}

function createWidget(data) {
    const request = {
        method: 'post',
        path: 'tags/tag-source/create',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        properties: data
    }

    return (new api()).sendRequest(request).then(response => {
        return new Promise((resolve, reject) => {
            if (response.data.status === 'success') {
                resolve(response.data.data)
            }
            if (response.data.status === 'error') {
                reject(response.data.data)
            }
        });
    })
}

function updateWidget(data) {
    const request = {
        method: 'post',
        path: 'tags/tag-source/update',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        properties: data
    }

    return (new api()).sendRequest(request).then(response => {
        return new Promise((resolve, reject) => {
            if (response.data.status === 'success') {
                resolve(response.data.data)
            }
            if (response.data.status === 'error') {
                reject(response.data.data)
            }
        });
    })
}

async function getTemplateWidgets(data = {}) {
    const request = {
        method: 'get',
        path: 'tags/template/index',
        properties: data,
        params: data
    }
    return (new api()).sendRequest(request).then(response => {
        return new Promise((resolve, reject) => {
            if (response.data.status === 'success') {
                resolve(response.data.data)
            }
            if (response.data.status === 'error') {
                reject(response.data.data)
            }
        });
    })
}

async function getTemplateWidgetsTags() {
    const request = {
        method: 'get',
        path: 'tags/template/tags',
        properties: {}
    }
    return (new api()).sendRequest(request).then(response => {
        return new Promise((resolve, reject) => {
            if (response.data.status === 'success') {
                resolve(response.data.data)
            }
            if (response.data.status === 'error') {
                reject(response.data.data)
            }
        });
    })
}

async function getTemplateWidget(data = {}) {
    const request = {
        method: 'get',
        path: 'tags/template/get-template',
        properties: data,
        params: data
    }
    return (new api()).sendRequest(request).then(response => {
        return new Promise((resolve, reject) => {
            if (response.data.status === 'success') {
                resolve(response.data.data)
            }
            if (response.data.status === 'error') {
                reject(response.data.data)
            }
        });
    })
}